import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    turbo: { type: Boolean, default: false },
  }

  redirectToUrl(event) {
    event.preventDefault()

    const url = event.currentTarget.dataset.url
    const frame = event.currentTarget.dataset.turboFrame
    const action = event.currentTarget.dataset.turboAction

    if (this.turboValue) {
      const options = { frame: frame }
      if (action && action !== 'replace') {
        options.action = action
      }

      Turbo.visit(url, options)
    } else {
      document.location.href = url
    }
  }
}
